.project-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: url("https://cdna.artstation.com/p/assets/images/images/047/186/144/original/stoffler-planeta10801.gif?1646960090") center center no-repeat;
  background-size: cover;
  padding: 20px;
}

.project-title {
  color: #ffffff;
  font-size: 5rem;
  font-weight: bold;
  text-align: center;
  text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.5);
  margin-top: 50px;
  margin-bottom: 30px;
  padding: 20px;
  border-radius: 15px;
}

.carousel {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  width: 80%;
  
  margin: 0 auto;
  padding: 20px 0;
}

.carousel-item {
  flex: 0 0 auto;
  width: 80%; /* Adjust the width as needed */
  height: 600px; /* Adjust the height as needed */
  margin-right: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.carousel-item:last-child {
  margin-right: 0;
}

.carousel-item:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.carousel-item iframe,
.carousel-item img {
  width: 100%;
  height: 100%;
}

.project-label {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
  padding: 15px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  border-top: 4px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.project-label:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.previous,
.next {
  background-color: transparent;
  border: 2px solid #fff;
  color: #fff;
  cursor: pointer;
  font-size: 25px;
  font-weight: bold;
  padding: 14px 28px;
  border-radius: 50px;
  text-align: center;
  text-decoration: none;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
  margin-top: 40px;
  margin-right: 20px;
}

.previous:hover,
.next:hover {
  transform: scale(1.1);
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.9), 0 8px 16px rgba(0, 0, 0, 0.4);
  background-color: rgba(255, 255, 255, 0.1);
}