html {
  scroll-behavior: smooth;
}

section {
  align-items: center;
  justify-content: center;
  height: 100%;
}

.contactPage {
background-color: black;  
align-items: center;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;


}

.click {
  color: rgb(252, 252, 252);
  animation: glowEffect 2s ease-in-out infinite alternate;
  border-radius: 15px;
  font-size: 5rem;
  font-weight: bold;
  letter-spacing: 2px;
  line-height: 1.2;
  margin-top: 50px;
  padding: 20px;
  text-align: center;
  text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.5);
}


.mailto {
  background: url("../Images/58485698e0bb315b0f7675a8.png");
  position: relative;
  top: -10px;
  display: block;
  width: 380px;
  height: 100px;
  display: block;
  height: 250px;
  font-size: 30px;
  color: #1b1b1b;
  background-color: rgb(0, 0, 0);
  border-radius: 10px;
  opacity: 0.5;
  transition-duration: 0.4s;
  outline: none;
  padding: 10px;
  font-weight: bold;
  text-align: center;

  color: rgb(240, 236, 236);
}

.mailto:hover {
  color: rgb(255, 255, 255);
  opacity: 0.9;
}

.email{
  color: rgb(252, 252, 252);
  border-radius: 15px;
  font-size: 2rem;
  font-weight:bold;
  letter-spacing: 2px;
  line-height: 1.2;
  padding: 20px;
  text-align: center;
  text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.5);
}