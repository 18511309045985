.pageContainer {
  align-items: center;
  background: url("https://cdna.artstation.com/p/assets/images/images/047/186/144/original/stoffler-planeta10801.gif?1646960090");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 1.5vmin);
  justify-content: center;
  min-height: 100vh;

}

.frontPageHeader {
  animation: glowEffect 2s ease-in-out infinite alternate;
  border-radius: 15px;
  color: #ffffff;
  font-size: 5rem;
  font-weight: bold;
  letter-spacing: 2px;
  line-height: 1.2;
  margin-bottom: 30px;
  margin-top: 50px;
  padding: 20px;
  text-align: center;
  text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.5);


}

@keyframes glowEffect {
  from {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3), 0px 0px 10px rgba(255, 255, 255, 0.5);
    transform: scale(1.02);

     /* Slight zoom-in effect */
  }
  to {
    text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.5), 0px 0px 15px rgba(255, 255, 255, 0.8);
    transform: scale(1);

     /* Reset to original size */
  }
}

@media screen and (max-width: 600px) {
  .frontPageHeader {
    font-size: 3rem;
     /* Adjust as needed */
  }
}

.container{
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding-bottom: 50px;
  width: 100%;

   /* or you can set a specific height if needed */
   /* Add padding to the bottom */
}

.Buttons {
  background-color: transparent;
  border: 2px solid #fff;
  border-radius: 50px; /* Making the button round */
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: 'Avenir', sans-serif;
  font-size: 25px;
  font-weight: bold;
  overflow: hidden;
  padding: 14px 28px;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
}
@keyframes pulse {
  0% {
    box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.6), 0px 4px 8px rgba(0, 0, 0, 0.2);
    transform: scale(1);
  }
  100% {
    box-shadow: 0px 0px 25px rgba(255, 255, 255, 0.8), 0px 6px 12px rgba(0, 0, 0, 0.3);
    transform: scale(1.05);
  }
}

.Buttons:before {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  bottom: 2px;
  content: "";
  left: 2px;
  opacity: 0.6;
  position: absolute;
  right: 2px;
  top: 2px;
  transition: opacity 0.3s ease;

   /* Slight black overlay for 3D effect */
   /* Slight rounded corners to match the button */
}

.Buttons:hover {
  box-shadow: 0px 0px 30px rgba(255, 255, 255, 0.9), 0px 8px 16px rgba(0, 0, 0, 0.4);
  transform: translateY(-2px) scale(1.07);

   /* Slight upward shift and scale on hover */
}
.Buttons:hover:before {
  opacity: 0.4;
   /* Adjust the opacity on hover */
}


.Buttons:active {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  transform: translateY(0);
   /* Reset the position on active click */
}

  body, html {
    height: 100%;
    overflow-y: auto;

     /* This allows for vertical scrolling */
  }

  /* Use a custom font */
body {
  font-family: "Open Sans", sans-serif;
}

/* Use whitespace to improve readability */
.Bio-content {
  align-items: center;
  background: url("https://images.unsplash.com/photo-1660914256311-918659fae88f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  justify-content: center;
  min-height: 100vh;
  overflow: hidden;

   /* Add this line to hide any content that extends beyond the element */
}

.bioParagraph {
  animation: Gradient s ease infinite;
  background-size: 200% auto;
  border-radius: 10px 30px 10px 30px;
  color: rgb(181, 188, 194);
  font-family: 'Avenir', sans-serif;
  font-weight: 600;
  margin: 0;
  margin-bottom: 20px;
  padding: 10px 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease-in-out;

   /* Add space between paragraphs */
}
@keyframes Gradient {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

.bottomOfPage {
  padding-bottom: 50px;
  text-align: center;

}
.bottomOfPage.show {
  display: block;
   /* Add this selector to show the bar when the user scrolls down */
}

.bioTitle{
  animation: glowEffect 2s ease-in-out infinite alternate;
  color: #ffffff;
  font-size: 5rem;
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: 50px;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.jose_hike {
  border: 2px solid #e0e0e0;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  display: block;
  height: auto;
  margin: 20px 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 30%;
  width: auto;
  /* Set a max width to ensure the image doesn't overflow its container */
  /* Add some space around the image */
  /* Add a subtle shadow for depth */
  /* Optional: Add a border-radius for rounded corners */
  /* Optional: Add a border */
  /* Ensure the image scales down if it's too large */
  /* Center the image if it's inside a container */
}

/* If you want a hover effect */
.jose_hike:hover {
  transform: scale(1.25);
  transition: transform 0.3s ease;
  /* Slightly increase the size of the image on hover */
  /* Transition for smooth scaling */
}

